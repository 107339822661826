import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  log(message: string, type: string = 'Info') {
    // You can customize the logging behavior here:
    // - Log to the console
    console.log(`${type}: ${message}`);

    // - Send logs to a server (e.g., using an API call)
    // - Use a dedicated logging library (e.g., ngx-logger)

    // ... other logging actions
  }

  error(message: string, error: any = null) {
    // Log errors with a specific type
    this.log(message, 'Error');
    if (error) {
      console.error('Error Details:', error);
    }
  }
}
