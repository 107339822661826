@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap";

/* src/app/shared/components/loader/loader.component.scss */
body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #3f4047;
  background-color: #fff7e7;
}
span.p-element.p-dropdown-label.p-inputtext.p-placeholder,
.p-element.p-dropdown-label {
  line-height: 12px !important;
  font-size: 14px;
}
.p-multiselect-label.p-placeholder,
.p-multiselect .p-multiselect-label {
  line-height: 17px !important;
  font-size: 14px;
  color: #6b7280 !important;
}
.p-calendar {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}
.p-calendar input::placeholder,
.p-calendar input::-ms-input-placeholder,
input::placeholder,
input::-ms-input-placeholder {
  color: #6b7280 !important;
}
.p-calendar .p-inputtext {
  font-size: 14px;
  border: none !important;
  height: 42px !important;
  color: #6b7280 !important;
}
.loader {
  width: 50px !important;
  height: 50px !important;
}
.overlay {
  z-index: 1010 !important;
}
.text-button {
  color: rgb(52, 52, 52) !important;
}
nav ul li {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  margin-bottom: 5px;
}
nav ul li:hover,
nav ul li:active {
  border-radius: 4px;
  border: 1px solid #969696;
}
nav ul li div {
  border-radius: 4px;
}
nav ul ul {
  padding: 0;
  margin: 0;
}
nav ul li li,
nav ul li li:hover {
  border: none;
}
nav ul li li:hover {
  background-color: rgb(255, 234, 191) !important;
}
.p-dropdown .p-dropdown-panel li.p-dropdown-item:hover {
  background-color: #eedebd !important;
  color: #202020 !important;
}
.p-dropdown-header .p-dropdown-filter-container input.p-dropdown-filter,
.p-multiselect-filter-container input.p-multiselect-filter {
  padding: 5px 10px;
  border: 1px solid #c4c4c4;
}
.text-red-500,
.p-dropdown-empty-message {
  font-size: 12px;
}
.p-datatable-table tbody td {
  padding: 5px !important;
  font-size: 12px !important;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header {
  padding: 10px;
}
.p-datatable-thead tr th {
  padding: 5px !important;
}
input,
table td,
button {
  font-size: 14px !important;
}
.p-tree-container li {
  padding: 0px;
}
.p-tree-container li li {
  padding-left: 20px;
}
.p-tree-container li li label {
  font-weight: 400;
  color: #545454;
}
.p-tree-container.p-treenode-selectable {
  padding: 0px;
}
.left_menu ul ul {
  padding: 0;
  margin: 0;
}
.left_menu ul ul li {
  margin-bottom: 0;
  margin-top: 1px;
}
.text-3xl {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 1.2rem;
}
.text-xl {
  font-size: 1rem;
}
.p-tabview-panel label {
  padding-bottom: 5px;
}
input[type=text] {
  height: 43px;
  border: 1px solid #ced4da !important;
}
input[type=text]::placeholder {
  color: #6b7280 !important;
}
ul.space-y-2 li {
  box-shadow: none !important;
}
footer {
  width: calc(98vw - 16rem) !important;
}
.profile_main .inp_w input {
  max-width: 450px;
}
.profile_main .inp_w .flex.flex-col.sm\:flex-row input {
  max-width: 395px;
}
header img {
  padding-left: 12px !important;
}
.font-semibold,
.font-bold,
.p-tabview-nav a {
  font-weight: 500;
}
.un_inv span {
  padding-right: 7px;
}
.profile_main .p-tabview-panels {
  overflow: auto;
}
#sidebar-wrapper.fixed {
  z-index: 9;
}
dashboard-filter .chip-area .chip {
  font-size: 12px;
  line-height: 12px;
}
.drivers-main form .flex-1 {
  max-width: 300px;
}
.status_c {
  width: 8px;
  height: 8px;
}
.tree-n p-treenode {
  font-weight: 600;
}
.tree-n p-treenode label {
  color: #78491f;
}
.p-paginator.p-component button.p-highlight {
  color: #fff;
  background-color: #e0a721;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
}
@-moz-document url-prefix() {
  body,
  .overflow-auto {
    scrollbar-color: rgb(246, 183, 9) rgb(220, 220, 220);
    scrollbar-width: thin;
  }
  body::-webkit-scrollbar,
  .overflow-auto::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  body::-webkit-scrollbar-track,
  body::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  }
}
@media (max-width: 767px) {
  .mobile {
    padding-right: 15px;
  }
  .p-menu.p-component.p-menu-overlay,
  .rev_ord {
    width: 100% !important;
  }
  .container-fluid,
  .tasks_table,
  .search_b_or,
  sub-account {
    overflow: auto;
  }
  .search_b_or form div {
    padding-right: 15px;
  }
  .container,
  .tasks_table .p-datatable-wrapper table,
  table.p-datatable-table {
    width: 1000px;
  }
  .min-h-screen.bg-body {
    width: 265px;
    overflow: auto;
  }
  .rev_ord table {
    width: 400px;
    overflow: auto;
  }
  footer {
    width: 88vw !important;
    font-size: 12px;
  }
  dashboard-filter {
    padding-top: 15px;
    display: block;
  }
  .text-center.text-gray-600.text-sm.p-3.bg-authBody {
    text-align: left !important;
  }
  zone-filter .bg-white.p-4.rounded.shadow-md,
  .drivers-main .bg-white.p-4.rounded.shadow-md,
  reports-version .bg-white.rounded-lg.shadow-md,
  org-filter .bg-white.p-4.rounded.shadow-md {
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  footer .justify-center {
    justify-content: left !important;
    padding-left: 15px;
  }
  .p-dialog-content .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .p-paginator.p-component {
    padding: 0;
  }
  .text-2xl {
    font-size: 1rem;
  }
  .profile_main input,
  .p-password {
    width: 350px;
    max-width: 350px !important;
  }
  .profile_main .flex.flex-col.sm\:flex-row input {
    max-width: 300px !important;
  }
  .profile_main .flex-col {
    flex-direction: row;
  }
  .profile_main .custom-btn,
  .profile_main .custom-btn:last-child {
    margin-bottom: 10px !important;
    width: inherit;
  }
  p-radiobutton {
    padding-right: 5px;
  }
  subac-permission .custom-btn {
    width: fit-content;
    display: inline-block;
  }
  .grid_c2_r.grid.grid-cols-4 {
    display: block;
  }
  .bg-white.shadow-lg.mb-6.rounded-lg.p-5 {
    padding: 10px !important;
  }
  .d-block-r {
    display: block;
  }
  .d-block-r .text-left {
    padding-bottom: 20px;
  }
  .p-paginator.p-component button {
    min-width: 22px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  dashboard-filter {
    padding-top: 15px;
    display: block;
  }
  .container,
  .tasks_table .p-datatable-wrapper table,
  .p-datatable-wrapper table {
    width: 1600px !important;
  }
  table.p-datatable-table {
    width: 100%;
  }
  .min-h-screen.bg-body {
    width: 100%;
    overflow: auto;
  }
  .container-fluid,
  .tasks_table,
  .search_b_or,
  sub-account {
    overflow: auto;
  }
  .profile_main input,
  .p-password {
    width: 350px;
    max-width: 350px !important;
  }
  .profile_main .flex.flex-col.sm\:flex-row input {
    max-width: 300px !important;
  }
  .profile_main .flex-col {
    flex-direction: row;
  }
  .profile_main .custom-btn,
  .profile_main .custom-btn:last-child {
    margin-bottom: 10px !important;
    width: inherit;
  }
  p-radiobutton {
    padding-right: 5px;
  }
  subac-permission .custom-btn {
    width: fit-content;
    display: inline-block;
  }
  footer {
    width: calc(100vw - 5rem) !important;
  }
  .btn-cntnr button[type=button] {
    margin-bottom: 10px !important;
  }
  p-tree .p-tree.p-component {
    padding: 0;
  }
  .rev_ord.w-50 {
    width: 80% !important;
  }
  .grid_c2_r.grid.grid-cols-4 {
    display: block;
  }
  .p-paginator.p-component button {
    min-width: 40px;
  }
}
@media (min-width: 1537px) {
  .container {
    max-width: 100%;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}
.loader {
  position: fixed;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  width: 96px;
  height: 96px;
  background-color: rgba(255, 255, 255, 0.8);
  transform: rotate(45deg);
  overflow: hidden;
  z-index: 9999;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 12px;
  margin: auto;
  background: #3f4047;
}
.loader:before {
  content: "";
  position: absolute;
  inset: -22.5px;
  margin: auto;
  background: #fcba2b;
  animation: diamondLoader 2s linear infinite;
}
@keyframes diamondLoader {
  0%, 10% {
    transform: translate(-96px, -96px) rotate(-45deg);
  }
  90%, 100% {
    transform: translate(0px, 0px) rotate(-45deg);
  }
}
/*# sourceMappingURL=loader.component.css.map */
