// src/app/core/services/title.service.ts

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { appSettings } from '@app/configs'; // Import your appSettings

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Update the document title
        this.updateTitle();
      });
  }

  /**
   * Updates the document title based on the current route's title metadata.
   */
  private updateTitle(): void {
    const route = this.getRouteSnapshot(this.activatedRoute.root);
    const title =
      route && route.data && route.data['title']
        ? route.data['title']
        : 'Default Title';
    const concatenatedTitle = `${appSettings.appTitle} | ${title}`;
    this.titleService.setTitle(concatenatedTitle);
  }

  /**
   * Recursively retrieves the route snapshot to find the title data.
   * @param route - The current activated route.
   * @returns The route snapshot with data.
   */
  private getRouteSnapshot(route: ActivatedRoute): any {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }
}
