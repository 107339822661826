// src/app/app.providers.ts

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { SuccessInterceptor } from './core/interceptors/success.interceptor';

import { TitleService } from './core/services/title.service';
import { LoadingStrategiesService } from './core/services/loading-strategies.service';
import { provideRouter, withPreloading } from '@angular/router';
import { routes } from './app.routes';
import { HttpService } from './core/http/http.service';
import { MessageService } from 'primeng/api';
import { provideAnimations } from '@angular/platform-browser/animations';

import 'firebase/auth'; // Import the authentication module

import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { ToastService } from './shared/services/toast.service';
import { firebaseProviders } from './firebase.provider';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { TrimInputDirective } from './shared/directives/trim-input.directive';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@env/environment';

/**
 * Provides the necessary providers for the application.
 * This includes HTTP interceptors, application-wide services,
 * router configuration, HTTP client setup, animations, toast notifications, and Firebase configuration.
 *
 * @type {Array<any>} An array of providers to be included in the Angular application.
 */
export const appProviders = [
  /**
   * HTTP Interceptors
   * Interceptors to handle various aspects of HTTP requests and responses.
   * - `AuthInterceptor`: Manages authentication tokens.
   * - `ErrorInterceptor`: Handles HTTP errors.
   * - `SuccessInterceptor`: Processes successful HTTP responses.
   *
   * @type {Object} HTTP_INTERCEPTORS
   * @property {Type} useClass - The interceptor class to be used.
   * @property {boolean} multi - Indicates that multiple interceptors can be provided.
   */
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptor, multi: true },

  /**
   * Services
   * Registers application-wide services for various functionalities.
   * - `TitleService`: Manages the document title.
   * - `LoadingStrategiesService`: Provides custom loading strategies.
   * - `HttpService`: Handles HTTP operations.
   * - `MessageService`: Provides messaging functionality for PrimeNG.
   *
   * @type {Array<Type>} An array of service classes to be registered.
   */
  TitleService,
  LoadingStrategiesService, // Register the service for custom loading strategies
  HttpService,
  MessageService,

  /**
   * Router Configuration
   * Provides router configuration with a preloading strategy for optimized route loading.
   *
   * @type {Object} Router configuration
   * @property {Routes} routes - The application's routing configuration.
   * @property {Function} withPreloading - Function to apply a custom preloading strategy.
   */
  provideRouter(routes, withPreloading(LoadingStrategiesService)),

  /**
   * HTTP Client
   * Provides HTTP client configuration with interceptors applied from dependency injection.
   *
   * @type {Object} HTTP client configuration
   * @property {Function} withInterceptorsFromDi - Function to apply interceptors.
   */
  provideHttpClient(withInterceptorsFromDi()),

  /**
   * Animations
   * Provides Angular animation support configuration.
   *
   * @type {Object} Animation configuration
   */
  provideAnimations(),

  /**
   * Toast Notifications
   * Provides components and services for displaying toast notifications.
   * - `ToasterComponent`: Component to display toast messages.
   * - `ToastService`: Service for managing toast notifications.
   *
   * @type {Array<Type>} An array of toast components and services.
   */
  ToasterComponent,
  ToastService,

  /**
   * Firebase
   * Provides Firebase initialization and authentication configuration.
   * - `firebaseProviders`: Array of Firebase-related providers.
   *
   * @type {Array<Type>} An array of Firebase providers.
   */
  ...firebaseProviders,

  // Other Components and Directives
  TruncatePipe,
  TrimInputDirective,
];
