import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { appSettings, RoutePaths } from '@app/configs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { FirebaseService } from '@app/core/authentication/firebase.service';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { ProfileUpdateService } from '@app/shared/services/profile-update.service';
import { ToastService } from '@app/shared/services/toast.service';
import { TUserProfile } from '@app/shared/types/common.types';
import { decrypt } from '@app/shared/utilities';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';

/**
 * The ManagerHeaderComponent renders the header
 * containing user information, profile menu options, and logout.
 */
@Component({
  selector: 'manager-header',
  standalone: true,
  imports: [
    RouterModule,
    ButtonModule,
    DropdownModule,
    MenuModule,
    CommonModule,
    TruncatePipe,
  ],
  templateUrl: './manager-header.component.html',
  styleUrls: ['./manager-header.component.scss'],
})
export class ManagerHeaderComponent implements OnInit, OnDestroy {
  /**
   * The user's full name, constructed from the decrypted user profile.
   * @type {string}
   */
  userName: string = '';

  /**
   * The user's email address, displayed in the menu.
   * @type {string}
   */
  userEmail: string = '';

  /**
   * The user's initials, extracted from the first and last names.
   * @type {string}
   */
  userInitials: string = '';

  /**
   * The user's role, determined by account type and permissions.
   * @type {string}
   */
  userRole: string = '';

  /**
   * The route paths used for navigation.
   * @type {typeof RoutePaths}
   */
  public route: typeof RoutePaths = RoutePaths;

  /**
   * Menu items for user email, profile, and logout. Populated with user email dynamically.
   * @type {Array<{label: string, icon: string, command?: Function}>}
   */
  menuItems = [
    { label: 'Email', icon: 'pi pi-fw pi-envelope' },
    {
      label: 'My Profile',
      icon: 'pi pi-fw pi-user',
      command: () => this.router.navigate([this.route.PROFILE]),
    },
    {
      label: 'Logout',
      icon: 'pi pi-fw pi-sign-out',
      command: () => this.handleLogout(),
    },
  ];

  /**
   * Subscription for tracking profile update messages.
   * @type {Subscription}
   */
  private subscription: Subscription;

  /**
   * Constructor initializes the component with injected services.
   *
   * @param {AuthenticationService} authenticationService - Service for handling authentication tasks.
   * @param {FirebaseService} firebaseServices - Service for Firebase-related authentication tasks.
   * @param {ToastService} toast - Service for showing toast notifications.
   * @param {Router} router - Service for handling navigation.
   * @param {TruncatePipe} truncatePipe - Pipe for truncating text.
   * @param {ProfileUpdateService} profileUpdateService - Service for tracking profile update messages.
   */
  constructor(
    private authenticationService: AuthenticationService,
    private firebaseServices: FirebaseService,
    private toast: ToastService,
    private router: Router,
    private truncatePipe: TruncatePipe,
    private profileUpdateService: ProfileUpdateService
  ) {}

  /**
   * Angular lifecycle hook that runs after component initialization.
   * Decrypts user details stored in cookies, populates header with user data,
   * and subscribes to profile update notifications.
   */
  ngOnInit(): void {
    // Subscribe to profile update notifications
    this.subscription = this.profileUpdateService.currentMessage.subscribe(
      (status: boolean) => {
        this.updateUserDetails();
      }
    );
  }

  /**
   * Decrypts the user details and updates the header with user information.
   */
  private updateUserDetails(): void {
    try {
      const encryptedUserDetails = this.authenticationService.getCookie(
        appSettings.userDetails
      );
      if (encryptedUserDetails) {
        const decryptedUserDetails = decrypt(encryptedUserDetails);
        const UProfile = JSON.parse(decryptedUserDetails) as TUserProfile;
        this.populateUserInformation(UProfile);
      } else {
        console.warn('No encrypted user details found.');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error decrypting user details:', error.message);
      } else {
        console.error('Error decrypting user details: Unknown error');
      }
    }
  }

  /**
   * Populates the component's properties with user information.
   *
   * @param {TUserProfile} UProfile - The decrypted user profile.
   */
  private populateUserInformation(UProfile: TUserProfile): void {
    this.userName = `${UProfile.first_name || ''} ${UProfile.last_name || ''}`;
    this.userEmail = UProfile.email || '';
    this.userRole = `${
      UProfile.account_type === 'franchisee' ? 'Manager' : 'Sub Manager'
    }${UProfile.super_user === 'true' ? ' (Super User)' : ''}`;
    this.userInitials = this.getInitials(
      UProfile.first_name,
      UProfile.last_name
    );

    this.menuItems[0].label = this.truncatePipe.transform(this.userEmail, 50);
  }

  /**
   * Angular lifecycle hook for cleanup when the component is destroyed.
   * Unsubscribes from the profile update service to prevent memory leaks.
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Extracts initials from the first and last names.
   *
   * @param {string} firstName - The user's first name.
   * @param {string} lastName - The user's last name.
   * @returns {string} The user's initials.
   */
  private getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  }

  /**
   * Handles logout by clearing cookies, signing out from Firebase,
   * navigating to the login page, and showing a success message.
   *
   * @returns {Promise<void>} A Promise that resolves when the logout process is complete.
   */
  public async handleLogout(): Promise<void> {
    try {
      // Clear authentication cookies
      this.authenticationService.deleteCookie(appSettings.userDetails);
      this.authenticationService.deleteCookie(appSettings.token);
      this.authenticationService.deleteCookie(appSettings.firebaseToken);

      // Sign out from Firebase
      await this.firebaseServices.signOutFromFirebase();

      // Navigate to login page
      this.router.navigateByUrl(RoutePaths.LOGIN);

      // Show logout success notification
      this.toast.showSuccess('You have been logged out successfully!');
    } catch (error) {
      console.error('Logout error:', error);
      // Optionally handle logout error
    }
  }
}
