import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';
import { AuthenticationService } from '../authentication';

/**
 * Enum to define HTTP status codes for easier reference
 */
export enum StatusCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  // Add other status codes you want to handle
}

@Injectable()
/**
 * ErrorInterceptor class that implements HttpInterceptor to handle and log HTTP errors.
 */
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * Constructor to inject LoggerService and AuthenticationService.
   *
   * @param logger - Service used to log errors
   * @param authenticationService - Service used to manage user authentication
   */
  constructor(
    private logger: LoggerService,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Intercepts HTTP requests to handle errors and log them based on their status codes.
   *
   * @param req - The outgoing HTTP request
   * @param next - The next interceptor in the chain
   * @returns An Observable of the HTTP event, or an Observable error if an error occurs
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('ErrorInterceptor - Error:', error.message);

        // Handle error based on HTTP status codes
        switch (error.status) {
          case StatusCodes.BAD_REQUEST:
            // Log the error message for a Bad Request (400)
            this.logger.error('Bad Request: ', error.message);
            break;
          case StatusCodes.UNAUTHORIZED:
            // Handle Unauthorized (401) and log out the user
            // this.logger.error('Unauthorized: ', error.message);
            this.authenticationService.logout();
            break;
          case StatusCodes.NOT_FOUND:
            // Handle Not Found (404) errors
            this.logger.error('Not Found: ', error.message);
            break;
          case StatusCodes.INTERNAL_SERVER_ERROR:
            // Handle Internal Server Error (500)
            this.logger.error('Internal Server Error: ', error.message);
            break;
          default:
            // Log any other error status
            this.logger.error('An error occurred during the request.', error.message);
        }

        // Re-throw the error so it can be handled by other components or services
        return throwError(() => error.error || error);
      })
    );
  }
}
