// src/main.ts
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { appProviders } from '@app/app.providers';

bootstrapApplication(AppComponent, {
  ...appConfig,
  // providers: [...appProviders],
}).catch((err) => console.error(err));
