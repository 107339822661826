import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { ToastService } from './shared/services/toast.service';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { appSettings } from './configs';
import { LoaderService } from './shared/services/loader.service';
import { CommonModule } from '@angular/common';
import { TitleService } from './core/services/title.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonModule,
    ToastModule,
    LoaderComponent,
    CommonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [ToastService, MessageService],
})
export class AppComponent implements OnInit {
  title = appSettings.appTitle;
  public isLoaderOn: boolean = false;

  constructor(
    private _loader: LoaderService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this._loader.castLoader.subscribe((loader) => this.loader(loader));

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this._loader.showLoader();
    //     setTimeout(() => {
    //       this._loader.hideLoader();
    //     }, 5000);
    //   }
    // });
  }

  /*
   * loader
   */
  public loader(data: string) {
    switch (data) {
      case 'showloader':
        setTimeout(() => {
          this.isLoaderOn = true;
        }, 0);
        break;
      case 'hideloader':
        setTimeout(() => {
          this.isLoaderOn = false;
        }, 0);
        break;
    }
  }
}
