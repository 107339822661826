<div class="m-login__signin">
  <div class="m-login__head">
    <h3 class="m-login__title">Forgot Password</h3>
  </div>
  <form
    class="m-login__form m-form"
    [formGroup]="forgotPasswordForm"
    novalidate
  >
    <div class="form-group m-form__group">
      <input
        type="email"
        class="form-control m-input"
        formControlName="emailAddress"
        [placeholder]="'Email Address'"
        appTrimInput
      />
      <span
        class="text-danger"
        *ngIf="
        (formControls['emailAddress'].touched || submitted) &&
        formControls['emailAddress'].errors?.['required']
      "
      >
        {{ errors.EMAIL }}
      </span>

      <span
        class="text-danger"
        *ngIf="formControls['emailAddress'].errors?.['pattern']"
      >
        {{ errors.INVALID_EMAIL }}
      </span>
    </div>
    <br /><br />
    <div class="m-login__form-action">
      <button
        class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
        type="submit"
        (click)="forgotPassword()"
        [disabled]="!forgotPasswordForm.valid"
      >
        <span>Submit</span>
      </button>
    </div>
  </form>

  <span>Click here to <a href="/">Login</a> </span>
</div>
