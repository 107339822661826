import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import apiEndPointsConfig from '@app/configs/api-end-points.config';
import { HttpService } from '@app/core/http';
import {
  IOrganization,
  IState,
  IZone,
  IDriver,
  IFilterData,
} from '@app/shared/interfaces/common2.interface';
import { LoaderService } from '@app/shared/modules/loader';
import { SnackBarService } from '@app/shared/services/snack-bar.service';
import { TResponse } from '@app/shared/types/response.types';
import { dateFormat } from '@app/shared/utilities';
import * as moment from 'moment';

/**
 * Component for filtering data by organization, state, zones, and drivers.
 */
@Component({
  selector: 'common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})
export class CommonFilterComponent implements OnInit, OnDestroy {
  @Output() filterApplied = new EventEmitter<IFilterData>(); // EventEmitter to emit filter data
  @Input() showDriversSelection: boolean;
  @Input() reportType: string = '';
  @Input() minDate: Date; // Input for minimum date
  @Input() maxDate: Date; // Input for maximum date

  form: FormGroup; // Reactive form for filtering
  organizations: IOrganization[] = []; // List of organizations
  states: IState[] = []; // List of states
  zones: IZone[] = []; // List of zones
  drivers: IDriver[] = []; // List of drivers
  selectedOrganizationId: number | null = null; // Currently selected organization ID
  selectedState: IState | null = null; // Currently selected state
  formSubmitted: boolean = false;
  private subscriptions: Subscription[] = []; // Array to manage subscriptions

  /**
   * Constructor for CommonFilterComponent.
   * @param loader Service for showing and hiding loaders
   * @param formBuilder Service for building reactive forms
   * @param snackBar Service for showing snack bar messages
   * @param http Service for making HTTP requests
   */
  constructor(
    private loader: LoaderService,
    private formBuilder: FormBuilder,
    private snackBar: SnackBarService,
    private http: HttpService
  ) {
    this.form = this.formBuilder.group({
      organization: [null, Validators.required],
      state: [null, Validators.required],
      zones: [[], Validators.required], // Array to hold selected zones
      drivers: [[], this.showDriversSelection ? Validators.required : null], // Conditional validation
      startDate: [null, Validators.required], // Initialize start date with required validator
      endDate: [null, Validators.required],
    });
  }

  /**
   * Lifecycle hook that is called after the component is initialized.
   */
  ngOnInit(): void {
    this.getOrganizationData();

    // Re-apply the validators to ensure they are set
    this.form.get('startDate')?.updateValueAndValidity();
    this.form.get('endDate')?.updateValueAndValidity();
  }

  /**
   * Fetches organization data from the server and updates the organizations list.
   */
  private getOrganizationData(): void {
    this.loader.showLoader();
    const organizationDataSubscription = this.http
      .get<TResponse>(`${apiEndPointsConfig.organizations}`, {
        useUrlPrefix: true,
      })
      .subscribe({
        next: (response: TResponse) => {
          this.organizations = response.dataset?.['organizations_list'] || [];
        },
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
    if (organizationDataSubscription) {
      this.subscriptions.push(organizationDataSubscription);
    }
  }

  /**
   * Fetches states for a given organization ID and updates the states list.
   * @param organizationId ID of the selected organization
   */
  /**
   * Fetches the list of states for a given organization and filters them based on the report type.
   *
   * @param {number} organizationId - The ID of the organization to fetch states for.
   * @returns {void}
   */
  private getStates(organizationId: number): void {
    this.loader.showLoader();
    const statesSubscription = this.http
      .get<TResponse>(`${apiEndPointsConfig.states}/${organizationId}`, {
        useUrlPrefix: true,
      })
      .subscribe({
        /**
         * Handles the successful response from the API.
         *
         * @param {TResponse} response - The API response containing the list of states.
         */
        next: (response: TResponse) => {
          this.states = response.dataset['states_list'];

          switch (this.reportType) {
            case 'NV':
              this.states = this.states.filter((state) => state.code !== 'CA');
              break;
            case 'CA':
              this.states = this.states.filter((state) => state.code === 'CA');
              break;
            default:
              // Default case: show all states
              this.states = response.dataset['states_list'];
              break;
          }

          this.form.get('state')?.enable();
        },
        /**
         * Handles errors from the API call.
         *
         * @param {any} error - The error object returned by the API.
         */
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        /**
         * Executes when the API call completes, regardless of success or error.
         */
        complete: () => {
          this.loader.hideLoader();
        },
      });

    if (statesSubscription) {
      this.subscriptions.push(statesSubscription);
    }
  }

  /**
   * Fetches zones for a given organization ID and state ID, and updates the zones list.
   * @param organizationId ID of the selected organization
   * @param stateId ID of the selected state
   */
  private getZones(organizationId: number, stateId: number): void {
    this.loader.showLoader();
    const zonesSubscription = this.http
      .get<TResponse>(
        `${apiEndPointsConfig.zones}/${organizationId}/${stateId}`,
        {
          useUrlPrefix: true,
        }
      )
      .subscribe({
        next: (response: TResponse) => {
          this.zones = response.dataset['zones_list'];
          this.form.get('zones')?.enable();
        },
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
    if (zonesSubscription) {
      this.subscriptions.push(zonesSubscription);
    }
  }

  /**
   * Fetches drivers for the selected zones.
   * @param selectedZones Array of selected zone IDs
   */
  private getDrivers(selectedZones: number[]): void {
    this.loader.showLoader();
    const params = {
      filter_by: 'organization',
      zone_id: selectedZones.join(','), // Convert array to comma-separated string
    };
    const driversSubscription = this.http
      .post<TResponse>(`${apiEndPointsConfig.orgMoDrivers}`, params, {
        useUrlPrefix: true,
      })
      .subscribe({
        next: (response: TResponse) => {
          this.drivers = response.dataset['master_drivers_list']; // Assign drivers data
          this.form.get('drivers')?.enable();
        },
        error: (error) => {
          this.snackBar.notifyError(error.message);
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
    if (driversSubscription) {
      this.subscriptions.push(driversSubscription);
    }
  }

  /**
   * Handles the organization selection event.
   * @param organizationId ID of the selected organization
   */
  public onOrganizationSelect(organizationId: number): void {
    this.selectedOrganizationId = organizationId;
    this.selectedState = null;
    this.states = [];
    this.zones = [];
    this.drivers = []; // Clear drivers data
    this.form.get('zones')?.setValue([]);
    this.form.get('drivers')?.setValue([]);

    this.form.get('state')?.disable();
    this.form.get('zones')?.disable();
    this.form.get('drivers')?.disable();

    this.getStates(organizationId);
  }

  /**
   * Handles the state selection event.
   * @param selectedState The selected state object
   */
  public onStateSelect(selectedState: IState): void {
    this.selectedState = selectedState;

    if (this.selectedOrganizationId) {
      this.getZones(this.selectedOrganizationId, selectedState.id);
    }
  }

  /**
   * Submits the form data and emits it to the parent component.
   */
  public onSubmit(): void {
    if (
      dateFormat(this.form.get('startDate')?.value) >
      dateFormat(this.form.get('endDate')?.value)
    ) {
      this.snackBar.notifyError('Start date cannot be greater than end date.');
      return;
    }
    const startMoment = moment(this.form.get('startDate')?.value);
    const endMoment = moment(this.form.get('endDate')?.value);

    if (!this.minDate && !this.maxDate) {
      // Calculate difference in days
      const differenceInDays = endMoment.diff(startMoment, 'days');
      if (differenceInDays > 30) {
        this.snackBar.notifyError(
          'The difference between the start date and end date cannot exceed 30 days.'
        );
        return;
      }
    }

    this.formSubmitted = true;
    if (this.form.valid) {
      const formData: IFilterData = {
        organization: this.form.get('organization')?.value,
        state: this.form.get('state')?.value,
        zones: this.form.get('zones')?.value,
        drivers: this.form.get('drivers')?.value,
        startDate: dateFormat(this.form.get('startDate')?.value) || '',
        endDate: dateFormat(this.form.get('endDate')?.value) || '',
      };
      this.filterApplied.emit(formData); // Emit the form data to parent component
    } else {
      // Optionally, display form errors or validation messages
      this.form.markAllAsTouched(); // Mark all fields as touched to show validation errors
    }
  }

  /**
   * Lifecycle hook that is called just before the component is destroyed.
   */
  ngOnDestroy(): void {
    // Clean up all subscriptions
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Handles selected options from the dropdowns and updates the form controls.
   * @param event Array of selected items (drivers or zones)
   * @param purpose Purpose of the selection (either 'zone' or 'driver')
   */
  public onSelectedOptions(event: IDriver[] | IZone[], purpose: string) {
    // Extracting IDs and joining them with commas
    const ids = event.map((item: IDriver | IZone) => item.id);

    switch (purpose) {
      case 'zone':
        this.form.get('zones')?.setValue(ids); // Update the zones form control with the selected zone IDs
        if (ids.length > 0) {
          this.getDrivers(ids);
        } else {
          this.drivers = []; // Clear drivers if no zones selected
          this.form.get('drivers')?.setValue([]);
        }
        break;

      case 'driver':
        this.form.get('drivers')?.setValue(ids); // Update the drivers form control with the selected driver IDs
        break;
    }
  }
}
