/* src/app/core/layouts/manager-header/manager-header.component.scss */
@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  .mobile {
    display: none;
  }
}
/*# sourceMappingURL=manager-header.component.css.map */
