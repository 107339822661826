<header class="bg-white shadow-md p-0">
  <div class="mx-auto flex justify-between items-center h-16 py-0">
    <div class="relative flex items-center">
      <!-- Logo -->
      <img
        src="assets/images/logo.png"
        alt="Logo"
        class="h-12 cursor-pointer"
        [routerLink]="route.HOME"
      />
      <!-- Vertical Line with Gradient -->
      <div
        class="h-12 w-[2px] bg-gradient-to-b from-transparent via-gray-400 to-transparent ml-4"
      ></div>
      <!-- Project Name -->
      <span class="text-lg font-semibold ml-4">Manager</span>
    </div>
    <!-- Navigation Links For Desktop-->
    <nav class="flex items-center space-x-4 desktop">
      <!-- User Initials in Orange Box -->
      <div class="flex items-center relative">
        <div
          class="bg-button text-white h-10 w-10 flex items-center justify-center rounded"
        >
          <span class="font-bold">{{ userInitials }}</span>
        </div>
        <div class="ml-2 flex flex-col">
          <span class="text-lg font-semibold" title="{{ userName }}">
            {{ userName | truncate : 25 }}
          </span>
          <span class="text-sm text-gray-500">{{ userRole }}</span>
        </div>
        <p-button
          icon="pi pi-ellipsis-v"
          (click)="menu.toggle($event)"
          class="ml-2"
        ></p-button>
        <p-menu
          #menu
          [model]="menuItems"
          [popup]="true"
          appendTo="body"
          [style]="{ width: '400px' }"
          [title]="this.menuItems[0].label"
        ></p-menu>
      </div>
    </nav>

    <!-- Navigation Links For Mobile-->
    <nav class="flex items-center space-x-4 mobile">
      <!-- User Initials in Orange Box -->
      <div class="flex items-center relative">
        <div
          class="bg-button text-white h-10 w-10 flex items-center justify-center rounded"
        >
          <span
            class="font-bold"
            title="{{ userName }}"
            (click)="menu.toggle($event)"
            >{{ userInitials }}</span
          >
        </div>

        <p-menu
          #menu
          [model]="menuItems"
          [popup]="true"
          appendTo="body"
          [style]="{ width: '400px' }"
        ></p-menu>
      </div>
    </nav>
  </div>
</header>
