import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

// Extend the Navigator interface to include the Network Information API
interface NavigatorConnection extends Navigator {
  connection?: {
    effectiveType: 'slow-2g' | '2g' | '3g' | '4g' | '5g';
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoadingStrategiesService implements PreloadingStrategy {
  private internetSpeed: string;

  constructor() {
    this.internetSpeed = this.getConnectionType();
    // console.log(
    //   `Initialized LoadingStrategiesService with connection type: ${this.internetSpeed}`
    // );
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // console.log(`Preloading strategy check for route: ${route.path}`);
    if (this.internetSpeed === '4g' || this.internetSpeed === '5g') {
      // console.log('Connection type is suitable. Preloading the module.');
      return load(); // Preload this module
    } else {
      // console.log(
      //   'Connection type is slow. Skipping preloading for this module.'
      // );
      return of(null); // Do not preload this module
    }
  }

  /**
   * Gets the connection type from the Network Information API.
   * @returns The connection type (e.g., '2g', '3g', '4g', '5g', or 'unknown').
   */
  private getConnectionType(): string {
    const navigatorConnection = navigator as NavigatorConnection;
    if (
      navigatorConnection.connection &&
      navigatorConnection.connection.effectiveType
    ) {
      return navigatorConnection.connection.effectiveType;
    } else {
      return 'unknown'; // Default to 'unknown' if the API is not supported
    }
  }
}
