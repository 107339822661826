import { Component, Input } from '@angular/core';
import { ManagerSidebarComponent } from '../manager-sidebar/manager-sidebar.component';
import { ManagerFooterComponent } from '../manager-footer/manager-footer.component';
import { ManagerHeaderComponent } from '../manager-header/manager-header.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'manager-layout',
  standalone: true,
  imports: [
    ManagerSidebarComponent,
    ManagerFooterComponent,
    ManagerHeaderComponent,
    RouterModule,
    CommonModule,
  ],
  templateUrl: './manager-layout.component.html',
  styleUrl: './manager-layout.component.scss',
})
export class ManagerLayoutComponent {
  isCollapsed: boolean = false;

  onCollapsedChange(event: boolean): void {
    this.isCollapsed = event;
  }
}
