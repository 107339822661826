import { startCase } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskBatchClassification',
})
export class NotePipe implements PipeTransform {
  // Public and private class variables: Internal state and configuration
  private classificationInfoMap = new Map([
    ['cold', { baseText: 'cold pack', pluralSuffix: '(s)' }],
    ['other', { baseText: 'loose item', pluralSuffix: '(s)' }],
    ['regular', { baseText: 'cardboard box', pluralSuffix: '(es)' }],
  ]);

  /**
   * *Transforms the batch items array into a formatted string based on item classification.
   *
   * @param batchItems - The list of items associated with a task, each having an optional classification type.
   * @returns A formatted string showing the count of items per classification type (regular, cold, other).
   *
   * @date 16 September 2024
   * @developer Rahul Kundu
   */
  public transform(batchItems: any[] | null | undefined): string[] | null {
    // Return a placeholder if the batchItems is null or undefined
    if (!batchItems) return null;

    // Create a map to track the counts of each classification type
    const countMap = new Map<string, number>([
      ['cold', 0],
      ['other', 0],
      ['regular', 0],
    ]);

    // Iterate over each batch item and count each classification type
    batchItems.forEach((item) => {
      // Destructure the item and extract `classification`
      const { classification } = item;

      // Skip the item if classification is falsy
      if (!classification) return;

      // Increment the count for the classification type if it exists in countMap
      if (countMap.has(classification)) {
        countMap.set(classification, (countMap.get(classification) || 0) + 1);
      }
    });

    // Construct the final output string and return
    return Array.from(countMap.entries())
      .map(([type, count]) => this.constructItemLabel(count, type))
      .filter((part) => !!part);
  }

  /**
   * *Constructs a formatted label for the item count and type, with proper pluralization.
   *
   * @param count - The number of items.
   * @param type - The classification type (either 'regular', 'cold', or 'other').
   * @returns A formatted string in the form of "X item(s)", or an empty string if count is 0.
   *
   * @date 16 September 2024
   * @developer Rahul Kundu
   */
  private constructItemLabel(count: number, type: string): string {
    // If the count is 0, return an empty string to omit this part from the final output
    if (count === 0) return '';

    // Get the classification data from the map, based on the type (e.g. 'cold', 'other', 'regular')
    const classificationInfo = this.classificationInfoMap.get(type);

    // If no valid classification type is found, return an empty string
    if (!classificationInfo) return '';

    // Determine the plural suffix based on the count
    const pluralSuffix = count !== 1 ? classificationInfo.pluralSuffix : '';

    // Return the formatted string in the form "X baseText(s)", startCase the baseText
    return `${count} × ${classificationInfo.baseText}${pluralSuffix}`;
  }
}
