import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimInput]'
})
export class TrimInputDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private control: NgControl
  ) {}

  // Trigger on blur event (when the input loses focus)
  @HostListener('blur', ['$event'])
  onBlur(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const trimmedValue = inputElement.value.trim();

    // Set the trimmed value in the view (UI)
    this.renderer.setProperty(inputElement, 'value', trimmedValue);

    // Update the form control value programmatically (removing spaces)
    if (this.control && this.control.control) {
      this.control.control.setValue(trimmedValue, { emitEvent: true });
    }
  }
}
