import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';
import { appSettings, RoutePaths } from '@app/configs';
import { AuthenticationService } from '../authentication/authentication.service';
import { ToastService } from '@app/shared/services/toast.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/shared/services/loader.service';
import { FirebaseService } from '../authentication/firebase.service';

export enum StatusCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private logger: LoggerService,
    private authenticationService: AuthenticationService,
    private firebaseService: FirebaseService,
    private toast: ToastService,
    private router: Router,
    private loader: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('ErrorInterceptor - Error:', error);

        switch (error.status) {
          case StatusCodes.BAD_REQUEST:
            this.logger.error('Bad Request: ', error);
            break;

          case StatusCodes.UNAUTHORIZED:
            this.logger.error('Unauthorized: ', error);

            // Handle the async logout process within the catchError block
            return from(this.handleLogout());

          case StatusCodes.NOT_FOUND:
            this.logger.error('Not Found: ', error);
            break;

          case StatusCodes.INTERNAL_SERVER_ERROR:
            this.logger.error('Internal Server Error: ', error);
            break;

          default:
            this.logger.error('An error occurred during the request.', error);
        }

        return throwError(() => error.error || error);
      })
    );
  }

  // Handle the async logout process
  private async handleLogout(): Promise<any> {
    try {
      // Clear cookies
      this.authenticationService.deleteCookie(appSettings.userDetails);
      this.authenticationService.deleteCookie(appSettings.token);
      this.authenticationService.deleteCookie(appSettings.firebaseToken);

      // Sign out from Firebase
      await this.firebaseService.signOutFromFirebase();

      // Navigate to login page
      this.router.navigateByUrl(RoutePaths.LOGIN);

      // Show a success toast message
      this.toast.showSuccess('You have been logged out successfully!');
      // hide the loader if on
      this.loader.hideLoader();
    } catch (error) {
      console.error('Logout error:', error);
      this.toast.showError('An error occurred during logout.');
    }

    // Since we're inside an interceptor, we return a throwError to propagate the error
    return throwError(() => new Error('Unauthorized'));
  }
}
