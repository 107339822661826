import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { RoutePaths } from './configs';
import { ManagerLayoutComponent } from './core/layouts/manager-layout/manager-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/noauth.guard';

export const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    // canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./pages/auth/auth.routes').then((m) => m.authRoutes),
    data: { title: 'Authentication' }, // Title for Auth Layout
  },

  {
    path: '',
    component: ManagerLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RoutePaths.DASHBOARD,
        loadChildren: () =>
          import('./pages/dashboard/dashboard.routes').then(
            (m) => m.dashboardRoutes
          ),
        data: { title: 'Dashboard' },
      },
      {
        path: RoutePaths.PROFILE,
        loadChildren: () =>
          import('./pages/profile/profile.routes').then((m) => m.profileRoutes),
        data: { title: 'Profile' }, // Title for Profile
      },
      {
        path: `${RoutePaths.DRIVERS}`,
        loadChildren: () =>
          import('./pages/drivers/drivers.routes').then((m) => m.driverRoutes),
        data: { title: 'Drivers' }, // Title for Drivers Page
      },
      {
        path: `${RoutePaths.TASKS}`,
        loadChildren: () =>
          import('./pages/tasks/tasks.routes').then((m) => m.taskRoutes),
        data: { title: 'Tasks' }, // Title for Tasks Page
      },
      {
        path: RoutePaths.REPORTS, // 'reports'
        loadChildren: () =>
          import('./pages/reports/reports.routes').then((m) => m.reportRoutes),
        data: { title: 'Reports' },
      },
    ],
  },
  {
    path: `${RoutePaths.INVITE}/:invitationCode`,
    loadComponent: () =>
      import('./pages/invite-page/invite-page.component').then(
        (m) => m.InvitePageComponent
      ),
    data: { title: 'Invitation' }, // Title for Invitation Page
  },

  {
    path: '**',
    loadComponent: () =>
      import('./pages/errors/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    data: { title: 'Page Not Found' }, // Title for 404 Page
  },
];
