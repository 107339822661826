import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { appSettings, RoutePaths } from '@app/configs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const token = this.authenticationService.getCookie(appSettings.token);

    if (token) {
      return true;
    } else {
      this.router.navigate([RoutePaths.LOGIN]);
      return false;
    }
  }
}
