<div class="m-login__signin">
  <div class="m-login__head">
    <h3 class="m-login__title">Reset Password</h3>
  </div>
  <form class="m-login__form m-form" [formGroup]="resetPasswordForm" novalidate>
    <div class="form-group m-form__group">
      <input
        type="password"
        class="form-control m-input"
        formControlName="password"
        [placeholder]="'New Password'"
        appTrimInput
      />
      <span
        class="text-danger"
        *ngIf="
      (formControls['password'].touched || submitted) &&
      formControls['password'].errors?.['required']
    "
      >
        {{ errors.PASSWORD }}
      </span>
    </div>

    <div class="form-group m-form__group">
      <input
        type="password"
        class="form-control m-input m-login__form-input--last"
        formControlName="confirm_password"
        [placeholder]="'Confirm Password'"
        required
        appTrimInput
      />
      <span
        class="text-danger"
        *ngIf="
      (formControls['confirm_password'].touched || submitted) &&
      formControls['confirm_password'].errors?.['required']
    "
      >
        {{ errors.CONFIRM_PASSWORD }}
      </span>
      <span
        class="text-danger"
        *ngIf="formControls['confirm_password'].errors?.['confirmedValidator']"
      >
        {{ errors.PASSWORD_NO_MATCH }}
      </span>
    </div>
    <br /><br />
    <div class="m-login__form-action">
      <button
        class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
        type="submit"
        (click)="resetPassword()"
      >
        <span>Reset</span>
      </button>
    </div>
  </form>
</div>
