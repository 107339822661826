<div class="flex flex-col min-h-screen">
  <!-- Header -->
  <header
    id="header-wrapper"
    class="bg-gray-200 fixed top-0 left-0 w-full z-50 h-15"
  >
    <manager-header></manager-header>
  </header>

  <!-- Main Content Area -->
  <div
    id="main-content"
    class="flex flex-row flex-grow h-[calc(100vh-60px)] overflow-hidden relative mt-15"
  >
    <!-- Sidebar -->
    <aside
      id="sidebar-wrapper"
      class="fixed top-15 left-0 h-[calc(100vh-60px)] w-64 bg-gray-200 border-r border-gray-300 transition-all duration-300 overflow-y-auto"
      [ngClass]="{ 'w-20': isCollapsed, 'w-64': !isCollapsed }"
    >
      <manager-sidebar
        (collapsedChange)="onCollapsedChange($event)"
      ></manager-sidebar>
    </aside>

    <!-- Page Content -->
    <main
      id="page-content-wrapper"
      [ngClass]="{ 'ml-20': isCollapsed, 'ml-64': !isCollapsed }"
      class="flex-grow transition-all duration-300 overflow-y-auto mt-15 pb-[calc(var(--footer-height)+80px)] mt-20"
    >
      <div class="container-fluid">
        <!-- Main content here -->
        <router-outlet></router-outlet>
      </div>
      <manager-footer></manager-footer>
    </main>
  </div>
</div>
