import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { appSettings } from '@app/configs';

@Injectable()
export class HttpAuthorizationHeaderInterceptor implements HttpInterceptor {
  /**
   * Constructor to inject the authentication service
   *
   * @param _authService - Service used to manage authentication and user tokens
   */
  constructor(private _authService: AuthenticationService) {}

  /**
   * Intercepts HTTP requests to add the Authorization header if a token exists
   *
   * @param request - The outgoing HTTP request
   * @param next - The next interceptor in the chain
   * @returns An Observable of the HTTP event
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig: any = {};

    // Retrieve the authentication token from cookies
    const token: string | null = this._authService.retrieveCookie(
      appSettings.token
    );

    // If a token is present, add it to the Authorization header
    if (!!token) {
      headersConfig['Authorization'] = 'Bearer ' + token;
    }

    // Clone the request with the updated headers
    const HTTPRequest = request.clone({ setHeaders: headersConfig });

    // Handle the HTTP request and manage errors
    return next.handle(HTTPRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('error', error.message);

        // If a 401 Unauthorized error occurs, log out the user
        if (error.status === 401) {
          this._authService.logout();
          // You can navigate to the login page or another page here if needed
          // this.router.navigate(['/login']);
        }

        // Return an observable error
        return throwError(() => error.error || error);
      })
    );
  }
}
