<nav id="sidebar" [ngClass]="{ 'w-64': !isCollapsed, 'w-20': isCollapsed }" class="fixed h-screen bg-gradient-to-b from-white to-yellow-50 top-16 left-0 border-r border-gray-300 transition-all duration-300 z-1000 flex flex-col items-center">
  <div class="flex flex-col">
    <!-- Expand/Collapse Button -->
    <button (click)="toggleSidebar()" class="absolute top-2 right-[-25px] w-10 h-10 rounded-full flex items-center justify-center bg-button text-white border-none shadow hover:bg-button-hover transition">
      <i class="fa" [ngClass]="{ 'fa-chevron-left': !isCollapsed, 'fa-chevron-right': isCollapsed }"></i>
    </button>

    <!-- Sidebar Menu -->
    <div class="left_menu mt-6 flex-grow w-full flex flex-col">
      <ul class="w-full flex flex-col p-0 m-0">
        <li *ngFor="let item of items" class="flex flex-col w-full">
          <div
            class="flex items-center p-2 hover:bg-activeMenuItem cursor-pointer"
            [ngClass]="{ 'bg-activeMenuItem text-white': isActive(item.route) }"
            (click)="item.command && item.command()"
          >
            <!-- Menu Icon -->
            <i [ngClass]="item.icon" class="text-tableHeader"></i>
            <!-- Menu Text -->
            <span [ngClass]="{ 'hidden': isCollapsed }" class="ml-2 text-button">{{ item.label }}</span>
          </div>

          <!-- Sub-menu (Recursive) -->
          <ul *ngIf="item.items" class="ml-4 flex flex-col">
            <li *ngFor="let subItem of item.items" class="flex items-center p-2 hover:bg-gray-100 cursor-pointer" [ngClass]="{ 'bg-activeSubMenuItem text-white': isActive(subItem.route) }" (click)="subItem.command()">
              <!-- Submenu Icon -->
              <i [ngClass]="subItem.icon" class="text-tableHeader"></i>
              <!-- Submenu Text -->
              <span [ngClass]="{ 'hidden': isCollapsed }" class="ml-2 text-button">{{ subItem.label }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
