import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ManagerHeaderComponent } from '../manager-header/manager-header.component';
import { MessageService } from 'primeng/api'; // Ensure you import this
import { routes } from '@app/app.routes';
import { RoutePaths } from '@app/configs';

@Component({
  selector: 'manager-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './manager-sidebar.component.html',
  styleUrls: ['./manager-sidebar.component.scss'],
})
export class ManagerSidebarComponent {
  isCollapsed = false;
  @Output() collapsedChange = new EventEmitter<boolean>();
  currentRoute: string = '';

  items = [
    {
      label: 'Dashboard',
      icon: 'pi pi-home',
      route: 'dashboard', // Corresponding route
      command: () => this.navigateTo('dashboard'),
    },
    {
      label: 'Tasks',
      icon: 'pi pi-list',
      route: 'tasks', // Corresponding route
      command: () => this.navigateTo('tasks'),
    },
    {
      label: 'Drivers',
      icon: 'pi pi-users',
      route: 'drivers', // Corresponding route
      command: () => this.navigateTo('drivers'),
    },
    {
      label: 'Reports',
      icon: 'pi pi-chart-line',
      route: 'reports', // Corresponding parent route for Reports
      items: [
        {
          label: 'Late Reports',
          icon: 'pi pi-clock',
          route: 'reports/late', // Corresponding route
          command: () => this.navigateTo('reports/late'),
        },
        {
          label: 'California Reports',
          icon: 'pi pi-map-marker',
          route: 'reports/california', // Corresponding route
          command: () => this.navigateTo('reports/california'),
        },
        {
          label: 'Other States',
          icon: 'pi pi-globe',
          route: 'reports/other', // Corresponding route
          command: () => this.navigateTo('reports/other-states'),
        },
      ],
    },
  ];

  constructor(private messageService: MessageService, private router: Router) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url; // Track the current route
    });
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.collapsedChange.emit(this.isCollapsed);
  }

  private navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isActive(label: string): boolean {
    const currentLabel = label.toLowerCase().replace(/\s/g, '');
    return this.currentRoute.includes(currentLabel);
  }
}
