import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  /**
   * Transforms the input text by truncating it to a specified limit, adding '...' at the end if necessary.
   * @param value - The input text to truncate.
   * @param limit - The maximum length of the text (default: 20).
   * @param trail - The trail string to append after truncating (default: '...').
   * @returns The truncated text.
   */
  transform(value: string, limit: number = 20, trail: string = '...'): string {
    if (!value) {
      return '';
    }
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
